var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: "开始制作",
          width: 700,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "workerBox" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("裱花师：")]),
                      _vm._l(_vm.workerList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "li",
                            class: _vm.workerIndex == index ? "activeLi" : "",
                            on: {
                              click: function ($event) {
                                return _vm.workerClick(index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.workerName))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "workerBox" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("裱花工序："),
                      ]),
                      _vm._l(_vm.processList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "li",
                            class: {
                              activeLi: _vm.processIndex == index,
                              disabled: item.disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.processClick(index, item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.processName))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.addBrand } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }