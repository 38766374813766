<template>
  <!-- 裱花盘点单明细 -->
  <div class="check-detail">
    <StoreDetail :billType="billType" ref="storeDetail" :tableMiddleSpecialCount="2" :importInfo="importInfo">
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <div class="searchItem div-number-item">
          <el-form-item label="盘点名称" prop="checkName">
            <el-input v-model="scoped.form.checkName" disabled placeholder=""></el-input>
          </el-form-item>
        </div>
        <div class="searchItem">
          <el-form-item label="盘点仓库" prop="storeId">
            <SelectRemote
              class="marR15"
              v-model="scoped.form.storeId"
              style="width: 250px"
              disabled
              :option="$select({ key: 'listDecorInventory' }).option"
            />
          </el-form-item>
        </div>
      </template>
      <template #tableMiddleSpecial>
        <el-table-column
          label="账面数"
          align="center"
          prop="basUnitAccountQty"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="基本单位盘点数"
          align="center"
          prop="basUnitQty"
          show-overflow-tooltip
          width="120"
        ></el-table-column>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
import StoreDetail from "@/views/components/bill/storeDetail.vue";
import { decorationCheckInfoImport } from '@/api/decoration/bill/checkUrl' //裱花上传

import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "DecorationCheckDetail",
  components: { StoreDetail, selectStore, storeAdd, SelectRemote },
  data() {
    return {
      // billType: "130101",
      billType: "170309",
      //打开选择仓库开关
      OpenStore: false,
      //打开新增仓库开关
      OpenStoreAdd: false,
      importInfo:{
        uploadApi:decorationCheckInfoImport
      }
    };
  },
  methods: {
    //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
    getStore(event) {
      this.$refs.storeDetail.getStore(event, "form", "storeId", "selectStore", true);
    },
  },
};
</script>
