<!-- 裱花订单制作 -->
<template>
    <div class="wrap">
        <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
        <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />

        <!-- 新增和单独编辑对话框 -->
        <c-dialog title="开始制作" :width="700" :showDialog.sync="dialogFormVisible">
            <template #content>
                <div style="display: flex;">
                    <div class="workerBox">
                        <div class="title">裱花师：</div>
                        <div @click="workerClick(index)" v-for="(item, index) in workerList"
                            :class="workerIndex == index ? 'activeLi' : ''" :key="index" class="li">{{ item.workerName
                            }}</div>
                    </div>
                    <div class="workerBox">
                        <div class="title">裱花工序：</div>
                        <div @click="processClick(index,item)"
                            v-for="(item, index) in processList"
                            :class="{'activeLi':processIndex == index,'disabled':item.disabled}"
                            :key="index" class="li">
                            {{ item.processName}}</div>
                    </div>
                </div>
            </template>
            <template #buttonList>
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="addBrand">确 定</el-button>
            </template>
        </c-dialog>
    </div>

</template>
<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import TablePage from "@/components/tablePage";
import Dialog from '@/components/Dialog'
import { listBrand } from "@/api/decoration/base/teacher.js";
import { listProcess } from "@/api/decoration/base/process.js";
import {
    listOrder,
    getOrderDetailIds,
    UpdateBatchSaveOrder,
    deliveryShopBatchOrder,
    deliveryShopBatchUpdate
} from "@/api/decoration/bill/orderManage.js"
export default {
    components: { TablePage, Dialog, CDialog },
    name: 'orderManage',
    dicts: ["bill_status",],
    data() {
        return {
            formLabelWidth: "120px", //表单宽度
            workerList: [],
            workerIndex: 0,
            processList:[],
            processIndex: 0,
            dialogFormVisible: false,
            dialogOptions: {
                curType: '',
                title: '选择裱花间',
                width: 1250,
                show: false,
                type: 'TreeAndTable'
            },
            options: {
                mutiSelect: true, // 多选
                rideoCheck: true,
                rideoStatus: '1',
                rideoDataList: [
                    {
                        label: '制作中',
                        value: '1',
                    },
                    {
                        label: '已完成',
                        value: '3',
                    },
                    {
                        label: '已配送',
                        value: '4',
                    },
                ],
                loading: true,
                pagination: {
                    total: 0,
                    page: 1,
                    size: 15,
                },
                exportOption: {
                    show: true,
                    exportApi: 'decorationOrderExport',
                    exportName: '裱花订单制作列表',
                    timeout: 60 * 5 * 1000
                },
                defaultBody: { orderInnerStatus: 1 },
                getListApi: listOrder,
                body: {},
                listNo: true, // 序号
                title: "单据信息",
                check: [], // 选中数据
                rowKey: "billDetailId",
                search: [
                    {
                        label: "单据日期",
                        type: "datetimerange",
                        model: "",
                        filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
                    },
                    {
                        keyIndex: 0,
                        format: "datetimerange",
                        type: "timeRange",
                        defaultValue: "month",
                        data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
                    },
                    {
                        type: "filters",
                        tip: "全部/订单编号/门店名称/门店编号",
                        isRestore: true,
                        model: "",
                        filters: [
                            { filter: "query", label: "全部" },
                            { filter: "billNos", label: "订单编号" },
                            { filter: "shopNames", label: "门店名称" },
                            { filter: "shopNos", label: "门店编号" },
                        ],
                    },
                    {
                        label: "配送日期",
                        seniorSearch: true,
                        type: "date",
                        model: "",
                        filter: "deliveryDate",
                    },
                    this.$select({ key: "listGoods", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listCategory", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "decorationListRoom", option: { seniorSearch: true, option: { multiple: true } } }),
                    { type: "button", tip: "查询", btnType: "primary", click: "search" },
                    {
                        type: "button",
                        tip: "高级查询",
                        btnType: "primary",
                        click: "seniorSearch",
                    },
                    { type: "button", tip: "重置", click: "reset" },
                ],
                buttons: [
                    {
                        click: "decorationProductFinish",
                        label: "生产完成",
                        type: "primary",
                        disabled: false,
                    },

                    // {
                    //   click: "decorationMoveToShop",
                    //   label: "调至门店",
                    //   type: "primary",
                    //   disabled: false,
                    // },
                    {
                        click: "refresh",
                        right: true,
                        label: "刷新",
                        icon: "el-icon-refresh",
                        type: "",
                    },
                ],
                columns: [
                    {
                        prop: "billNo",
                        label: "订单编号",
                        minWidth: 180,
                    },
                    {
                        prop: "billDate",
                        label: "订单日期",
                        minWidth: 120,
                    },
                    {
                        prop: "fetchShopNo",
                        label: "取货/配送门店编号",
                        minWidth: 120,
                    },
                    {
                        prop: "fetchShopName",
                        label: "取货/配送门店",
                        minWidth: 120,
                    },
                    {
                        prop: "orderStatus",
                        label: "完成状态",
                        // formatter: (v, row) => row.orderInnerStatus == 1 ? '待制作' : row.orderInnerStatus == 2 ? '制作完成' : '已调出',
                        minWidth: 120,
                    },
                    {
                        prop: "produceDecorationRoomWorker",
                        label: "裱花师",
                        minWidth: 100,
                    },
                    {
                        prop: "decorationRoomName",
                        label: "裱花间",
                        minWidth: 100,
                    },
                    {
                        prop: "fetchTypeName",
                        label: "取货方式",
                        minWidth: 100,
                    },
                    {
                        prop: "fetchDate",
                        label: "取货日期",
                        minWidth: 100,
                    },
                    {
                        prop: "fetchTime",
                        label: "取货时间",
                        minWidth: 100,
                    },
                    // {
                    //     prop: "categoryNo",
                    //     label: "类别编号",
                    //     minWidth: 140,
                    // },
                    // {
                    //     prop: "categoryName",
                    //     label: "类别名称",
                    //     minWidth: 160,
                    // },
                    {
                        prop: "goodsNo",
                        label: "商品编号",
                        minWidth: 160,
                    },
                    {
                        prop: "goodsName",
                        label: "商品名称",
                        minWidth: 160,
                    },
                    // {
                    //     prop: "barcode",
                    //     label: "条码",
                    //     minWidth: 120,
                    // },
                    {
                        prop: "goodsSpec",
                        label: "规格",
                        minWidth: 160,
                    },
                    // {
                    //     prop: "unitName",
                    //     label: "单位",
                    //     minWidth: 120,
                    // },
                    {
                        prop: "unitPrice",
                        label: "单价",
                        minWidth: 120,
                    },
                    {
                        prop: "unitQty",
                        label: "数量",
                        minWidth: 120,
                    },
                    {
                        prop: "unitMoney",
                        label: "金额",
                        minWidth: 120,
                    },
                ],
                list: [],
            },
        };
    },
    // beforeRouteEnter(to, from, next) {
    // //   next(async (vm) => {
    // //     //从首页待办业务进来
    // //     if (to.query.billStatus && from.fullPath == "/index") {
    // //       vm.options.defaultBody = { billStatus: to.query.billStatus };
    // //       vm.options.search[4].model = to.query.billStatus
    // //       vm.options.search[1].defaultValue = ''
    // //       let cloneData = cloneDeep(vm.options);
    // //       vm.options = null;
    // //       vm.options = cloneData;
    // //     }
    // //   });
    // },
    created(){
        //获取裱花师和裱花工序的数据
        listBrand({
            delFlag: 0
        }).then((res) => {
            this.workerList = res.rows;
            this.workerIndex = 0;
        })
        listProcess({
            delFlag: 0
        }).then((res) => {
            this.processList = res.rows;
            this.processIndex = 0;
        })
    },
    methods: {
        cancel() {
            this.dialogFormVisible = false;
        },
        //选择裱花师
        workerClick(index) {
            this.workerIndex = index;
        },
        //选择裱花工序
        processClick(index,item) {
            if(item.disabled) return;
            this.processIndex = index;
        },
        // 找出数组内的多个数组的共同项
        getCommonElementsMultiple (arrays)  {
            // 使用Set和展开运算符找到共同的值
            const commonValues = [...new Set(arrays.flat())].filter((item) =>
                arrays.every((subArray) => subArray.includes(item))
            );
            return commonValues;
        },
        //制作中商品完成制作的过程
        async addBrand() {
            const selectData = this.options.check;
            const roomOrderList = selectData.map((x) => ({
                billDetailId: x.billDetailId,
                billId:x.billId,
                goodsId:x.goodsId,
                decorationRoomId:x.decorationRoomId,
                decorationRoomWorkerId: this.workerList.length>0?this.workerList[this.workerIndex].workerId:'',
                processId:this.processList.length>0?this.processList[this.processIndex].processId:'',
            }));
            UpdateBatchSaveOrder({ roomOrderList }).then(() => {
                this.$nextTick(() => {
                    this.options.check = [];
                });
                this.$modal.msgSuccess(`操作成功`);
                this.dialogFormVisible = false;
                setTimeout(() => {
                    this.$refs.tablePage.getList();
                }, 500);
            });
        },
        async handleEvent(type, row) {
            const selectData = this.options.check;

            switch (type) {
                case "rideo":
                    {
                        if (this.options.rideoStatus == 1) {
                            this.options.buttons = [
                                {
                                    click: "decorationProductFinish",
                                    label: "制作完成",
                                    type: "primary",
                                    disabled: false,
                                },
                                {
                                    click: "refresh",
                                    right: true,
                                    label: "刷新",
                                    icon: "el-icon-refresh",
                                    type: "",
                                },
                            ]
                            this.options.defaultBody = {
                                orderInnerStatus: '1'
                            }
                        } else if (this.options.rideoStatus == 3) {
                            this.options.buttons = [
                                {
                                    click: "decorationMoveToShop",
                                    label: "调至门店",
                                    type: "primary",
                                    disabled: false,
                                },
                                {
                                    click: "deliveryToShop",
                                    label: "配送",
                                    type: "primary",
                                    disabled: false,
                                },
                                {
                                    click: "refresh",
                                    right: true,
                                    label: "刷新",
                                    icon: "el-icon-refresh",
                                    type: "",
                                },
                            ]
                            this.options.defaultBody = {
                                orderInnerStatus: '3'
                            }
                        } else {
                            this.options.buttons = [
                                {
                                    click: "refresh",
                                    right: true,
                                    label: "刷新",
                                    icon: "el-icon-refresh",
                                    type: "",
                                },
                            ]
                            this.options.defaultBody = {
                                orderInnerStatus: '5'
                            }
                        }
                        this.$refs.tablePage.getList();
                    }
                    break;
                case "decorationMoveToShop":
                    {
                        const arrData = selectData.map((x) => ({
                            billDetailId:x.billDetailId,
                            billId:x.billId,
                            decorationRoomId:x.decorationRoomId,
                            goodsId:x.goodsId
                        }));
                        const that = this
                        // let list = selectData.filter((item) => item.orderInnerStatus == '3')
                        // if (!list.length) {
                        //     return that.$message.error('请选择制作完成单据')
                        // }
                        deliveryShopBatchUpdate({list:arrData}).then(() => {
                            that.$nextTick(() => {
                                that.options.check = [];
                            });
                            that.$modal.msgSuccess(`操作成功`);
                            setTimeout(() => {
                                that.$refs.tablePage.getList();
                            }, 500);
                        });
                    }
                    break;
                case "deliveryToShop":
                    {
                        const arrData = selectData.map((x) => ({
                            billDetailId:x.billDetailId,
                            billId:x.billId,
                            decorationRoomId:x.decorationRoomId,
                            goodsId:x.goodsId
                        }));
                        const that = this
                        // let list = selectData.filter((item) => item.orderInnerStatus == '3')
                        // if (!list.length) {
                        //     return that.$message.error('请选择制作完成单据')
                        // }
                        deliveryShopBatchOrder({list:arrData}).then(() => {
                            that.$nextTick(() => {
                                that.options.check = [];
                            });
                            that.$modal.msgSuccess(`操作成功`);
                            setTimeout(() => {
                                that.$refs.tablePage.getList();
                            }, 500);
                        });
                    }
                    break;
                case "decorationProductFinish":
                    {

                        // let list = selectData.filter((item) => item.orderInnerStatus == '1')
                        // if (!list.length) {
                        //     return this.$message.error('请选择待制作单据')
                        // }
                        if(this.workerList.length>0||this.processList.length>0){
                            this.dialogFormVisible = true;
                            
                            let billDetailIds = selectData.map((x) => (x.billDetailId));
                            getOrderDetailIds({billDetailIds}).then(res=>{
                                const orderProcessList = res?.data || [];
                                const allArr = [];
                                orderProcessList.forEach((item) => {
                                    allArr.push(item.processIds || []);
                                });
                                // 先找出这些订单详情里面制作过的共同的工序，不允许再点击这个工序制作
                                const commonProcessArr = this.getCommonElementsMultiple(allArr);
                                this.processList.forEach((item) => {
                                    item.disabled = false;
                                });
                                this.processList.forEach((item) => {
                                    commonProcessArr.forEach((pro) => {
                                        if (item.processId === pro) {
                                            item.disabled = true;
                                        }
                                    });
                                });
                                const index = this.processList.findIndex((item) => !item.disabled);
                                this.processIndex = index;
                            })
                        }else{
                            this.addBrand();
                        }
                    }
                    break;
                case "dialogChange":
                    {
                        let list = this.dialogOptions.formData.table.check
                        const params = selectData.map((x) => ({
                            billDetailId: x.billDetailId,
                            freshlyBakedRoomWorkerId: list[0].workerId,
                            // orderStoreId: x.orderStoreId,
                            roomStoreId: x.roomStoreId,
                        }));
                        decorationProductFinish(params).then(() => {
                            this.$nextTick(() => {
                                this.options.check = [];
                            });
                            this.$modal.msgSuccess(`操作成功`);
                            setTimeout(() => {
                                this.$refs.tablePage.getList();
                            }, 500);
                        });
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.workerBox {
    width: 50%;
    overflow: hidden;
    height: 200px;
    overflow: scroll;
    padding:0 20px;

    .title{
        line-height:40px;
        font-weight: bold;
    }

    .li {
        width: 66px;
        height: 30px;
        border-radius: 10px;
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        background: #0370ff96;
        line-height: 30px;
        font-size: 12px;
        text-align: center;
        color: #fff;
    }

    .activeLi {
        color: #fff;
        background: #0370FF;

    }
    .disabled{
        background:#aaa;
    }
}

::-webkit-scrollbar {
    width: 4px;
    /* 设置滚动条的宽度 */
}

/* 定制滚动条轨道 */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 设置轨道的背景颜色 */
}

/* 定制滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
    background: #888;
    /* 设置滑块的背景颜色 */
}

/* 当滑块悬停或活动时的样式 */
::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    /* 设置滑块在悬停状态下的背景颜色 */
}
</style>