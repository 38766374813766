<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="96px">
    <div class="wrap" v-loading="loading" id="wrap">
      <topOperatingButton
        :disabled="disabled"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        @submitForm="submitForm"
        @addBill="addBill"
        @auditBill="auditBill"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button size="mini" :disabled="disabledIntroduce" @click="selectBills"
            >引入源单
          </el-button>
          <!-- <el-button
            size="mini"
            :disabled="disabledIntroduce"
            @click="cancelBills"
            >取消引入
          </el-button> -->
          <el-button size="mini" :disabled="single" @click="auditBill('终止')"
            >终止
          </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        :billStatus="form.billStatus"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="form.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker
                style="width: 250px"
                size="mini"
                v-model="form.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="disabled"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="调入仓库" prop="inStoreId">
              <SelectRemote
                v-model="form.inStoreId"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listDecoration',
                    option: {
                      option: { disabled: disabled, 
                        // remoteBody: { storeTypes: [1, 2] }
                      },
                    },
                  }).option
                "
              />
            </el-form-item>
            <el-form-item label="调出仓库" prop="outStoreId">
              <SelectRemote
                v-model="form.outStoreId"
                :disabled="true"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listStore',
                    option: {
                      option: { 
                        disabled: disabled, 
                       },
                    },
                  }).option
                "
              />
            </el-form-item>
          </div>
          <div class="x-f marT10 marB10">
            <el-form-item label="业务类型" prop="billCustomType">
              <el-select
                :disabled="true"
                style="width: 250px"
                size="mini"
                v-model="form.billCustomType"
                placeholder="请选择业务类型"
              >
                <el-option label="同价调拨" :value="1"></el-option>
                <el-option label="异价调拨" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="单据备注" prop="remark">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="form.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table
              ref="multipleTable"
              :data="form.details"
              border
              show-summary
              :summary-method="getSummaries"
              :max-height="tableHeight"
              :height="tableHeight"
            >
              <!--  @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave" -->
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <!-- <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"
                  ></i>
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"
                  ></i> -->
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="160" align="center" prop="goodsNo">
                <template slot-scope="scope">
                  <!-- :disabled="disabled" -->
                  <el-input
                    v-model="scope.row.goodsNo"
                    :disabled="true"
                    @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                    maxlength="20"
                  >
                    <!-- <i
                      slot="suffix"
                      class="el-icon-more more"
                      @click="selectGoods"
                    ></i> -->
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                width="120"
              >
              </el-table-column>
              <el-table-column label="条码" width="160" align="center" prop="barcode">
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec" width="120">
                <template slot-scope="scope">
                  <el-form-item>
                    <el-select
                      @change="unitSalePriceChange($event, scope.row)"
                      v-model="scope.row.unitId"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in scope.row.units"
                        :key="item.unitId"
                        :label="item.goodsSpec"
                        :value="item.unitId"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitName" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitId'"
                    :rules="rules[`details.unitId`]"
                  >
                    <el-select
                      @change="unitSalePriceChange($event, scope.row)"
                      v-model="scope.row.unitId"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in scope.row.units"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="调出数量"
                align="center"
                show-overflow-tooltip
                width="120"
                prop="importUnitQty"
              >
              </el-table-column>

              <el-table-column label="调入数量" align="center" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>调入数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitQty'"
                    :rules="rules[`details.unitQty`]"
                  >
                    <el-input
                      oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\d*\.?\d{0,8})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value"
                      @blur="onkeyupUnitQty(scope.row)"
                      v-model="scope.row.unitQty"
                      :disabled="disabled"
                      :sunyunFormat="(v) => $syInput(1, v)"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="调拨单价金额"
                align="center"
                min-width="150"
                v-if="form.billCustomType == 2"
              >
                <el-table-column
                  label="调拨单价(元)"
                  align="center"
                  width="120"
                  prop="unitPrice"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'details.' + scope.$index + '.unitPrice'"
                      :rules="rules[`details.unitPrice`]"
                    >
                      <el-input
                        type="number"
                        @mousewheel.native.prevent
                        @keyup.native="onkeyupUnitQty(scope.row)"
                        v-model="scope.row.unitPrice"
                        :disabled="true"
                        maxlength="7"
                        @input="
                          limitInputlength(
                            scope.row.unitPrice,
                            scope.row,
                            'unitPrice',
                            true
                          )
                        "
                        :sunyunFormat="(v) => $syInput(2, v)"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="调拨金额(元)"
                  align="center"
                  width="120"
                  prop="unitMoney"
                  :formatter="(row) => $syInput(3, row.unitMoney)"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="进价金额" align="center" min-width="150">
                <el-table-column
                  label="单位进价(元)"
                  align="center"
                  width="120"
                  prop="unitPurPrice"
                  :formatter="(row) => $syInput(2, row.unitPurPrice)"
                >
                </el-table-column>
                <el-table-column
                  label="进价金额(元)"
                  align="center"
                  width="120"
                  prop="unitPurMoney"
                  :formatter="(row) => $syInput(3, row.unitPurPrice)"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="售价金额" align="center" min-width="150">
                <el-table-column
                  label="单位售价(元)"
                  align="center"
                  width="120"
                  prop="unitSalePrice"
                  :formatter="(row) => $syInput(2, row.unitSalePrice)"
                >
                </el-table-column>
                <el-table-column
                  label="售价金额(元)"
                  align="center"
                  width="120"
                  prop="unitSaleMoney"
                  :formatter="(row) => $syInput(2, row.unitSaleMoney)"
                >
                </el-table-column>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remark">
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.remark"
                    :disabled="true"
                    maxlength="80"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        @getGoodS="getGoodS"
        ref="selectGoods"
      ></SelectGoods>
      <shopBills
        ref="shopBills"
        :openBills="openBills"
        :ishead="ishead"
        orderTypes="170306"
        :moveAffirmObj="moveAffirmObj"
        :detailList="form.details"
        @updateOpenBills="updateOpenBills"
        @shopBillsDate="shopBillsDate"
        @IntroductionStatus="IntroductionStatus"
      ></shopBills>
    </div>
  </el-form>
</template>

<script>
import SelectGoods from "@/views/components/selectGoodsUp";
import { listGoods } from "@/api/goods/goods"; //商品列表
import {
  addOrder,
  updateOrderStatus,
  getOrderDetail,
  updateOrder,
} from '@/api/decoration/bill/moveIn' //裱花调入
import { getNewDate } from "@/utils/newDate"; //到货时间
import { getBillNo } from "@/api/codeRule"; //单据号
import { unitSalePriceChange } from "@/utils/unitChange"; //多级单位切换函数
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import shopBills from "@/views/components/shopBills";
// import { getOrderDetail as shopOrderDetail } from "@/api/shop/bill/shopBills";
import { getFindDetailGoodsInfo } from "@/api/shop/bill/shopBills";
import { cancelImport } from "@/api/shop/bill/shopBills"; //取消引入
import { limitInputlength } from "@/utils/index.js"; //保留几位小数
import { fcount } from "@/utils";
export default {
  name: "MoveInDetail",
  components: {
    SelectGoods,
    topOperatingButton,
    cardTitleCom,
    SelectRemote,
    shopBills,
  },
  data() {
    return {
      ishead: false,
      disabledIntroduce: true, //引入单据禁用状态
      moveAffirmObj: {}, //引入信息
      openBills: false, //引入源单开关
      tableCellLabel: "序号",
      showAudit: true, //审核
      //打印
      PrintObj: {
        id: "print",
        popTitle: "打印的标题",
        extraCss: "https://www.google.com,https://www.google.com",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      },
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //当前表格宽度
      scrollLeft: null,
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //终止
      single: true,
      //新增按钮
      showAdd: false,
      oldForm: {}, //旧的表单
      //表单数据
      form: {
        billStatus: "", //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        arrivalDate: null, //到货日期
        produceOrderId: null, //生产班次
        inStoreId: null, //订货仓库
        outStoreId: null, //调出仓库
        billRemark: null, //备注
        //单据表格-订单明细
        details: [{}],
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入单据编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "请选择单据日期",
            trigger: ["blur", "change"],
          },
        ],
        inStoreId: [
          {
            required: true,
            message: "请选择调入仓库",
            trigger: ["blur", "change"],
          },
        ],
        outStoreId: [
          {
            required: true,
            message: "请选择调出仓库",
            trigger: ["blur", "change"],
          },
        ],
        arrivalDate: [
          {
            required: true,
            message: "请选择到货日期",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "调入数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus === "2") {
          //新增按钮
          this.showAdd = true;

          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "3") {
          //新增按钮
          this.showAdd = false;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "0") {
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;

          //禁用审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus === "4") {
          //新增按钮
          this.showAdd = false;

          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "") {
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //打开输入按钮、保存按钮
          this.disabled = false;
        }
      },
      immediate: true,
    },
    //侦听调入是否开启引入源单按钮
    "form.inStoreId": {
      handler(newVal) {
        if (this.form.inStoreId) {
          this.disabledIntroduce = false;
        } else {
          this.disabledIntroduce = true;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const res1 = await getOrderDetail(vm.$route.query.billId);
          //单据赋值
          vm.form = res1.data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.form));
        }
      } else if (vm.$route.query.type == "updateMoveAffirm") {
        vm.getBasicData();
        vm.form.inStoreId = vm.$route.query.moveAffirm.inStoreId;
        vm.form.outStoreId = vm.$route.query.moveAffirm.outStoreId;
        vm.openBills = true;
        vm.moveAffirmObj = {
          billTypes: [170306],
          inStoreId: vm.$route.query.moveAffirm.inStoreId,
          billId: vm.$route.query.moveAffirm.billId,
        };
      } else {
        await vm.getBasicData();
        vm.disabled = false;

        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.form = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.form));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form));
    }
    next();
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
    });
  },
  methods: {
    limitInputlength,
    //单据引入状态
    IntroductionStatus(val) {
      this.Introduction = val;
    },
    //关闭弹窗
    updateOpenBills(val) {
      this.openBills = val;
    },
    //引入源单商品
    async shopBillsDate(val) {
      console.log(val, 'val');
      
      if (!val) {
        // let { rows } = await shopOrderDetail([this.form.billId]);
        let { rows } = await getFindDetailGoodsInfo([this.form.billId]);
        val = rows;
      }
      val.forEach((v) => {
        v.importUnitQty = v.unitQty;
        v.unitMoney = v.unitQty * v.unitPrice;
        v.sourceBillId = v.billId;
        v.sourceBillIdNo = v.billNo;
        v.sourceBillDetailId = v.billDetailId;
        v.sourceBillType = v.billType;
        v.billId = null;
        v.billDetailId = null;
      });
      this.form.outStoreId = val[0].outStoreId;
      this.form.billCustomType = val[0].billCustomType;
      this.form.details = val;
    },
    //取消当前业务订单
    cancelBills() {
      this.$confirm("此操作将取消上一次引入并清空表格商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.form.billId) {
            await cancelImport(this.form.billId);
          }
          this.Introduction = false;
          this.form.details = [{}];
        })
        .catch(() => {});
    },
    //引入源单
    selectBills() {
      // if (this.Introduction) {
      //   this.$message.error('如需重新选择源单，请先取消引入')
      //   return
      // }
      if (this.form.details[0].goodsId) {
        this.ishead = true;
      } else {
        this.ishead = false;
      }
      this.$refs.shopBills.queryParams.billTypes = [140306, 130105];
      this.$refs.shopBills.queryParams.inStoreId = this.form.inStoreId;
      this.openBills = true;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    },
    unitSalePriceChange,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (this.form.billStatus === "0" || this.form.billStatus === "") {
        let eleIndex = this.form.details.indexOf(row);
        for (let index = 0; index < this.form.details.length; index++) {
          const element = this.form.details[index];
          if (eleIndex == index) {
            element["hoverRow"] = true;
          } else {
            element["hoverRow"] = false;
          }
        }
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.details.length; index++) {
        const element = this.form.details[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          (item) => item.path === this.$route.path
        ),
        1
      );
      this.$router.push("/decoration/bill/moveIn");
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      const res = await getBillNo(170306);
      this.form.billNo = res;
      this.loading = false;
    },
    //数量键盘抬起
    onkeyupUnitQty(row) {
      // 单价
      const unitMoney =
        row.unitQty && row.unitPrice ? fcount([row.unitQty, row.unitPrice], "*") : 0;
      this.$set(row, "unitMoney", unitMoney);
      this.$set(row, "unitPriceTaxMoney", unitMoney);

      // 进价
      const unitPurMoney =
        row.unitQty && row.unitPurPrice
          ? fcount([row.unitQty, row.unitPurPrice], "*")
          : 0;
      this.$set(row, "unitPurMoney", unitPurMoney);

      // 售价
      const unitSaleMoney =
        row.unitQty && row.unitSalePrice
          ? fcount([row.unitQty, row.unitSalePrice], "*")
          : 0;
      this.$set(row, "unitSaleMoney", unitSaleMoney);
    },
    //表单重置
    async reset() {
      //表单数据
      this.form = {
        billStatus: "", //单据审核状态
        billNo: null, //单据编号
        billDate: null, //单据日期
        arrivalDate: null, //到货日期
        produceOrderId: null, //生产班次
        inStoreId: null, //订货仓库
        outStoreId: null, //调出仓库
        billRemark: null, //备注
        //单据表格-订单明细
        details: [{}],
      };
      this.resetForm("form");
      const res2 = await getBillNo(170306);
      this.form.billDate = getNewDate();
      this.form.billNo = res2;
    },
    //初始化页面
    async getBasicData() {
      //获取单据日期
      this.form.billDate = getNewDate();
      this.form.arrivalDate = getNewDate();

      //新增时获取单据编号
      const res2 = await getBillNo(170306);
      this.form.billNo = res2;
    },
    //保存并新增单据
    async addBill() {
      this.loading = true;
      await this.submitForm(false);
      this.loading = false;
    },
    //订单明细表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        let name = "";
        if (index === 6) {
          name = "importUnitQty";
        } else if (index === 7) {
          name = "unitQty";
        } else if (index === 9) {
          if (this.form.billCustomType == 1) {
            name = "unitMoney";
          } else {
            name = "unitPurMoney";
          }
        } else if (index === 11) {
          if (this.form.billCustomType == 1) {
            name = "unitSaleMoney";
          } else {
            name = "unitMoney";
          }
        } else if (index === 13) {
          name = "unitSaleMoney";
        } else {
          sums[index] = "";
          return;
        }

        const values = data.filter((item) => !(item[`${name}`] === undefined));
        const values2 = values.map((i) => Number(i[`${name}`]));
        sums[index] = values2.reduce((prev, curr) => {
          return prev + curr;
        }, 0);
      });
      return sums;
    },

    //选中的商品
    getGoodS(value) {
      this.form.details = this.form.details.filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      );
      value.forEach((v) => {
        this.$set(v, "unitQty", "");
        this.$set(v, "unitPurPrice", v.purPrice);
        this.$set(v, "unitSalePrice", v.salePrice);
        this.$set(v, "unitPurMoney", 0);
        this.$set(v, "unitSaleMoney", 0);
        this.$set(v, "remark", "");
      });
      this.form.details.push(...value);
    },

    //表格单元格回车事件
    keyup(name, event, index) {
      //判断是否输入编码或条码
      if (this.form.details[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.form.details[`${index}`][`${name}`],
          goodsTypes: [0, 1, 2, 3, 4, 5],
        }).then((response) => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur();
            //原始赋值
            response.rows[0].remark = "";
            response.rows[0].unitQty = "";
            response.rows[0].unitPurPrice = response.rows[0].purPrice;
            response.rows[0].unitSalePrice = response.rows[0].salePrice;
            response.rows[0].unitPurMoney = 0;
            response.rows[0].unitSaleMoney = 0;
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.form.details, index, response.rows[0]);
          } else {
            this.queryCode = this.form.details[`${index}`][`${name}`];
            this.$refs.selectGoods.queryParams.goodsTypes = [0, 1, 2, 3, 4, 5];
            this.OpenGoods = true;
          }
        });
      } else {
        //没输入直接弹出商品信息框
        event.target.blur();
        this.$refs.selectGoods.queryParams.goodsTypes = [0, 1, 2, 3, 4, 5];
        this.OpenGoods = true;
      }
    },

    //选择商品方法
    selectGoods() {
      this.$refs.selectGoods.queryParams.goodsTypes = [0, 1, 2, 3, 4, 5];
      this.OpenGoods = true;
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.form.details.splice(index + 1, 0, {});
      } else {
        if (this.form.details.length <= 1){
          this.form.details = [{
            goodsNo: "",
            hoverRow: false,
          }]
        }else{
          this.form.details.splice(index, 1);
        }
      }
    },
    //审核单据方法
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }

          const obj = {
            billIds: [this.form.billId],
            billStatus: 2,
            billType: 170306,
          };
          await this.submitForm(true);
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("审核成功");
          //禁用全部输入
          this.disabled = true;
        } else if (name === "反审核") {
          if (this.form.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 0,
            billType: 170306,
          };
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("反审核成功");
          //恢复全部输入
          this.disabled = false;
        } else if (name === "终止") {
          if (this.form.billStatus != "2") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4,
            billType: 170306,
          };
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.form = res.data;
          // 弹出提示
          this.$message.success("单据关闭成功");
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm(isBool) {
      if (this.form.outStoreId === this.form.inStoreId) {
        this.$message.error("订货仓库和调出仓库不能选择同一个仓库");
        return;
      }
      if (
        !(JSON.stringify(this.form.details[0]) === "{}") &&
        !(this.form.details[0].goodsId === undefined)
      ) {
        const valid = await this.$refs.form.validate();
        // this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.form.billId) {
            const res = await updateOrder(this.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.form = res.data;
              this.$modal.msgSuccess("修改单据成功");
            } else {
              this.reset();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const res = await addOrder(this.form);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.form = res.data;
              this.$modal.msgSuccess("新增单据成功");
            } else {
              this.reset();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          }
        }
        // })
      } else {
        this.$message.error("单据明细不能为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
