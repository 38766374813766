var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "96px" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                disabled: _vm.disabled,
                showAudit: _vm.showAudit,
                isAddBtn: _vm.showAdd,
                id: "topOperatingButton",
              },
              on: {
                submitForm: _vm.submitForm,
                addBill: _vm.addBill,
                auditBill: _vm.auditBill,
                handleAdd: _vm.handleAdd,
                getQuit: _vm.getQuit,
              },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.auditBill("终止")
                        },
                      },
                    },
                    [_vm._v("终止 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.form.billStatus,
                id: "basicInformation",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.form.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billNo", $$v)
                            },
                            expression: "form.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disabled,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billDate", $$v)
                            },
                            expression: "form.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘亏仓库", prop: "outStoreId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listDecoration",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  // remoteBody: { storeTypes: [1, 2] }
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.form.outStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "outStoreId", $$v)
                            },
                            expression: "form.outStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f marT10 marB10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                          },
                          model: {
                            value: _vm.form.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billRemark", $$v)
                            },
                            expression: "form.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.form.details,
                          border: "",
                          "show-summary": "",
                          "summary-method": _vm.getSummaries,
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("push", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("del", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编码",
                            width: "160",
                            align: "center",
                            prop: "goodsNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("SelectRemote", {
                                    attrs: {
                                      size: "mini",
                                      disabled: _vm.disabled,
                                      option: _vm.optionGoods,
                                      filterable: true,
                                      placeholder: "选择商品",
                                    },
                                    model: {
                                      value: scope.row.goodsNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goodsNo", $$v)
                                      },
                                      expression: "scope.row.goodsNo",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            width: "180",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "条码",
                            width: "180",
                            align: "center",
                            prop: "barcode",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                            width: "150",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { disabled: _vm.disabled },
                                          on: {
                                            change: function ($event) {
                                              return _vm.unitSalePriceChange(
                                                $event,
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.unitId,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "unitId", $$v)
                                            },
                                            expression: "scope.row.unitId",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.units,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.unitId,
                                              attrs: {
                                                label: item.goodsSpec,
                                                value: item.unitId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品批号",
                            width: "160",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: scope.row.batchNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "batchNo", $$v)
                                      },
                                      expression: "scope.row.batchNo",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitName",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitId",
                                          rules: _vm.rules["details.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitSalePriceChange(
                                                  $event,
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "盘亏数量",
                              align: "center",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "details." +
                                            scope.$index +
                                            ".unitQty",
                                          rules: _vm.rules["details.unitQty"],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "number",
                                            disabled: _vm.disabled,
                                            maxlength: "7",
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.unitQty,
                                                scope.row,
                                                "unitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            mousewheel: function ($event) {
                                              $event.preventDefault()
                                            },
                                            keyup: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("盘亏数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "盘亏采购单价(元)",
                            align: "center",
                            width: "140",
                            prop: "unitPurPrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "盘亏采购金额",
                            align: "center",
                            width: "120",
                            prop: "unitPurMoney",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "盘亏销售单价(元)",
                            align: "center",
                            width: "140",
                            prop: "unitSalePrice",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "盘亏销售金额",
                            align: "center",
                            width: "120",
                            prop: "unitSaleMoney",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            align: "center",
                            prop: "remark",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      maxlength: "80",
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("SelectGoods", {
            ref: "selectGoods",
            attrs: { OpenGoods: _vm.OpenGoods, queryCode: _vm.queryCode },
            on: {
              "update:OpenGoods": function ($event) {
                _vm.OpenGoods = $event
              },
              "update:open-goods": function ($event) {
                _vm.OpenGoods = $event
              },
              getGoodS: _vm.getGoodS,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }